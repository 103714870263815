import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToLanding = () => redirectUnauthorizedTo(['']);
const redirectLoggedInToMain = () => redirectLoggedInTo(['main']);

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '',
    // canActivate:[AngularFireAuthGuard],
    // data: {authGuardPipe: redirectLoggedInToMain},
    loadChildren: () => import('./modules/authentication/authentication.module').then( m => m.AuthenticationPageModule)
  },
  {
    path: 'p',
    // canActivate:[AngularFireAuthGuard],
    // data: {authGuardPipe: redirectUnauthorizedToLanding},
    loadChildren: () => import('./modules/administration/administration.module').then( m => m.AdministrationPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
